import { Routes, Route } from "react-router-dom";
// import AuthState from "./contexts/AuthContext";
import Layout from "./pages/Layout";
import NowPage from "./pages/NowPage";
import PlantsPage from "./pages/PlantsPage";
import PlantPage from "./pages/PlantPage";
import PlantEffectsPage from "./pages/PlantEffectsPage";
import PlantRecipesPage from "./pages/PlantRecipesPage";
import CultivarPage from "./pages/CultivarPage"
import RecipesPage from "./pages/RecipesPage";
import RecipePage from "./pages/RecipePage";
import ConditionsPage from "./pages/ConditionsPage";
import ConditionPage from "./pages/ConditionPage";
import EffectsPage from "./pages/EffectsPage";
import EffectPage from "./pages/EffectPage";
import AboutPage from "./pages/AboutPage";
import "./stylesheets/App.css";

const App = () => {
  return (
    // <AuthState>
    <Routes>
      <Route element={<Layout />}>
        <Route path='/' element={<NowPage />} />
        <Route path='/plants' element={<PlantsPage />} />
        <Route path='/plants/:id' element={<PlantPage />} />
        <Route path='/plants/:id/recipes' element={<PlantRecipesPage />} />
        <Route path='/plants/:id/effects' element={<PlantEffectsPage />} />
        <Route path='/plants/:id/cultivars/:id' element={<CultivarPage />} />
        <Route path='/recipes' element={<RecipesPage />} />
        <Route path='/recipes/:id' element={<RecipePage />} />
        <Route path='/recipes/:id' element={<RecipePage />} />
        <Route path='/conditions' element={<ConditionsPage />} />
        <Route path='/conditions/:id' element={<ConditionPage />} />
        <Route path='/effects/:id' element={<EffectPage />} />
        <Route path='/about' element={<AboutPage />} />
        {/* <Route path='/signin' element={<Signin />} />
        <Route path='/signup' element={<Signup />} />
        <Route element={<ProtectedRoute />}>
          <Route path='/me' element={<Me />} />
        </Route> */}
      </Route>
    </Routes>
    // </AuthState>
  );
};

export default App;
