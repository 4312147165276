import { useEffect, useState } from "react";
import { useLocationContext } from "../contexts/LocationContext";
import EffectIdentity from "./EffectIdentity";

const DailyEffect = () => {
    const { location } = useLocationContext();
    const [effect, setEffect] = useState();
    const baseUrl = "https://plantea.aladlabs.net/api/v1/effects/daily/";
    const initialUrl = baseUrl + location;
    const [apiUrl, setApiUrl] = useState(initialUrl);

    const getData = async () => {
        try {
            const response = await fetch(apiUrl);
            const effect = await response.json();
            setEffect(effect);
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        setApiUrl(initialUrl);
    }, [location]);

    useEffect(() => {
        getData();
    }, [apiUrl]);

    return (
        <div className='grid_2-14'>
            <h1 className='section-label'>Effect of the day</h1>
            {effect && <EffectIdentity effect={effect}/>}
        </div>
    );
};

export default DailyEffect;
