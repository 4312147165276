import RecipeSpecies from "./RecipeSpecies";
import RecipeIngredients from "./RecipeIngredients";
import {NavLink} from "react-router-dom";

const RecipeIdentity = ({ recipe }) => {
    return (
        <div className=''>
            {recipe && <NavLink className={"section-title green"} to={"/recipes/" + recipe.idMeal}>
                {recipe.strMeal}
            </NavLink>}
            <div>{recipe && <RecipeSpecies ingredients={recipe.ingredients} />}</div>
            {/*<h1 className='section-subtitle'>Ingredients</h1>*/}
            {/*{recipe && <RecipeIngredients ingredients={recipe.ingredients} />}*/}
        </div>
    );
};

export default RecipeIdentity;
