import { useEffect, useState } from "react";
import { useLocationContext } from "../contexts/LocationContext";
import RecipeIdentity from "./RecipeIdentity";

const DailyRecipe = () => {
    const { location } = useLocationContext();
    const [recipe, setRecipe] = useState();
    const baseUrl = "https://plantea.aladlabs.net/api/v1/recipes/daily/";
    const initialUrl = baseUrl + location;
    const [apiUrl, setApiUrl] = useState(initialUrl);

    const getData = async () => {
        try {
            const response = await fetch(apiUrl);
            const json = await response.json();
            setRecipe(createRecipeIngredients(json));
        } catch (error) {
            console.log(error.message);
        }
    };

    const createRecipeIngredients = (recipe) => {
        recipe["ingredients"] = [];

        for (let i = 1; i <= 20; i++) {
            const ingredient = recipe[`strIngredient${i}`];
            const subspecieId = recipe[`subspecieId${i}`] || "";
            const measure = recipe[`strMeasure${i}`];
            const scientificName = recipe[`subspecieScientificName${i}`];

            if (ingredient != "" && ingredient != null) {
                recipe["ingredients"].push({
                    id: recipe.id,
                    name: ingredient,
                    scientificName: scientificName,
                    subspecieId: subspecieId,
                    measure: measure,
                });
            }
            delete recipe[`strIngredient${i}`];
            delete recipe[`subspecieId${i}`];
            delete recipe[`strMeasure${i}`];
            delete recipe[`subspecieScientificName${i}`];
        }

        return recipe;
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        setApiUrl(initialUrl);
    }, [location]);

    useEffect(() => {
        getData();
    }, [apiUrl]);

    return (
        <div className='grid_2-14'>
            <h1 className='section-label'>Recipe of the day</h1>
            {recipe && <RecipeIdentity recipe={recipe}/>}
        </div>
    );
};

export default DailyRecipe;
