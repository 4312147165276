const PlantIdentity = ({ plant, wpArticle }) => {
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

  return (
      <>
          <h1 className='section-subtitle'>Characteristics</h1>
          <table className='specie-details'>
              <tbody>
                  {plant.plantForm && (
                      <tr>
                          <td className='table-key'>Plant form:</td>
                          <td className='table-value'>{plant.plantForm}</td>
                      </tr>
                  )}
                  {plant.height && (
                      <tr>
                          <td className='table-key'>Height:</td>
                          <td className='table-value'>up to {plant.height} cm</td>
                      </tr>
                  )}
                  {plant.lifeCycle && (
                      <tr>
                          <td className='table-key'>Life cycle:</td>
                          <td className='table-value'>{plant.lifeCycle}</td>
                      </tr>
                  )}
                  {plant.deciduousOrEvergreen && (
                      <tr>
                          <td className='table-key'>Foliage:</td>
                          <td className='table-value'>{plant.deciduousOrEvergreen == "E" ? "evergreen" : "deciduous"}</td>
                      </tr>
                  )}
                  {plant.floweringTime && (
                      <tr>
                          <td className='table-key'>Flowering:</td>
                          <td className='table-value'>{plant.floweringTime.replace(/\d+/g, (match) => monthNames[Number(match) - 1])}</td>
                      </tr>
                  )}
                  {plant.scented !== 0 && (
                      <tr>
                          <td className='table-key'>Scent:</td>
                          <td className='table-value'>{plant.scented == 1 && "scented"}</td>
                      </tr>
                  )}
                  {plant.pollinators !== "" && (
                      <tr>
                          <td className='table-key'>Pollinators:</td>
                          <td className='table-value'>{plant.pollinators}</td>
                      </tr>
                  )}
              </tbody>
          </table>
          <div className='section-text'>
              <p>{plant.physicalCharacteristics}</p>
              { wpArticle &&
              <div><a href={wpArticle[3][0]} target="_blank" rel="noopener noreferrer" className={"list-more-link"}>More about this plant on wikipedia</a></div>
              }
          </div>
      </>
  );
};

export default PlantIdentity;
