import { useState, useEffect } from "react";
import PlantTile from "./PlantTile";

const EffectSpeciesGallery = ({ effect }) => {
  const [plants, setPlants] = useState();
  const baseUrl = "https://plantea.aladlabs.net/api/v1/effects/";
  const initialUrl = baseUrl + effect.id + "/species";
  const [apiUrl, setApiUrl] = useState(initialUrl);

  const getData = async () => {
    try {
      const response = await fetch(apiUrl);
      const json = await response.json();
      setPlants(json);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, [apiUrl]);

  return (
      <div className='grid_2-14'>
        <h1 className='section-subtitle'>Related species</h1>
        <div className='tile-grid tile-container'>
          {plants
            ? plants.map((plant) => {
                return (
                  <PlantTile
                    plant={plant}
                    id={plant.subspecieId}
                    key={plant.subspecieId}
                  />
                );
              })
            : "Hello"}
        </div>
      </div>
  );
};

export default EffectSpeciesGallery;
