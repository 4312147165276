const PlantMedicalUses = ({ plant, className }) => {
  return (
    <div  className={className}>
      <h1 className='section-subtitle'>Body effects</h1>
      <p>{plant.medicinalUses.replace(/\[[^\]]*\]/g, "")}</p>
    </div>
  );
};

export default PlantMedicalUses;
