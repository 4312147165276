import { useState } from 'react';
import AccordionSection from './AccordionSection';
import "../stylesheets/accordion.css"

const Accordion = ({ label, content }) => {
    const [openSections, setOpenSections] = useState({});

    const handleClick = (label) => {
        const isOpen = !!openSections[label];

        setOpenSections({
            ...openSections,
            [label]: !isOpen,
        });
    };

    return (
        <div className='accordion-wrapper mobile'>
            <AccordionSection
                key={label}
                isOpen={!!openSections[label]}
                label={label}
                onClick={handleClick}
            >
                {content}
            </AccordionSection>
        </div>
    );
};

export default Accordion;
