import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { pickRandomAmount } from "../utils/pickRandomAmount.js";

const PlantRecipes = ({ plant }) => {
  const [recipes, setRecipes] = useState();

  const baseUrl = "https://plantea.aladlabs.net/api/v1/species/";
  const initialUrl = baseUrl + plant.subspecieId + "/recipes";
  const [apiUrl, setApiUrl] = useState(initialUrl);

  const getData = async () => {
    try {
      const response = await fetch(apiUrl);
      const json = await response.json();
      if (json.length <= 3) {
        setRecipes(pickRandomAmount(json, json.length));
      } else {
        setRecipes(pickRandomAmount(json, 3));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, [apiUrl]);

  return (
    <>
      {recipes && recipes.length > 0 && (
        <>
          <h1 className='section-subtitle'>Recipes</h1>
          <div className='link-list'>
            {recipes.map((recipe) => {
              return (
                <div className={"list list-item"} key={recipe.idMeal}>
                  {">  "}
                  <NavLink
                    to={`/recipes/${recipe.idMeal}`}
                    key={recipe.idMeal}
                    className='list-item-link'
                  >
                    {recipe.strMeal}
                  </NavLink>
                  <br />
                </div>
              );
            })}
            <NavLink
              to={`/plants/${plant.subspecieId}/recipes`}
              className='list-more-link'
            >
              more recipes with this species
            </NavLink>
          </div>
        </>
      )}
    </>
  );
};

export default PlantRecipes;
