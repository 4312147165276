import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const ConditionEffects = ({ conditionId }) => {
  const [effects, setEffects] = useState();
  const baseUrl = "https://plantea.aladlabs.net/api/v1/conditions/";
  const initialUrl = baseUrl + conditionId + "/effects";
  const [apiUrl, setApiUrl] = useState(initialUrl);

  const getData = async () => {
    try {
      const response = await fetch(apiUrl);
      const json = await response.json();

      setEffects(json);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='link-list'>
      {effects &&
        effects.map((effect) => {
          return (
            <div key={effect.effectId} className={"list list-item"}>
              {">  "}
              <NavLink
                key={effect.effectId}
                to={`/effects/${effect.effectId}`}
                className='list-item-link'
              >
                {effect.effectName}
              </NavLink>
              <br />
            </div>
          );
        })}
    </div>
  );
};

export default ConditionEffects;
