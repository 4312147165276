import { useEffect, useState } from "react";
import PlantTile from "./PlantTile";
import { pickRandomAmount } from "../utils/pickRandomAmount.js";
import { useLocationContext } from "../contexts/LocationContext";

const PlantsSowOutside = () => {
    const { location } = useLocationContext();
    const [plants, setPlants] = useState();
    const baseUrl = "https://plantea.aladlabs.net/api/v1/species/sow-outside/";
    const initialUrl = baseUrl + location;
    const [apiUrl, setApiUrl] = useState(initialUrl);

    const getData = async () => {
        try {
            const response = await fetch(apiUrl);
            const json = await response.json();
            if (json.data.length != 0) {
                const selectedPlants = json.data;
                setPlants(pickRandomAmount(selectedPlants, 4));
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        setApiUrl(initialUrl);
    }, [location]);

    useEffect(() => {
        getData();
    }, [apiUrl]);

    return (
        <div className='grid_2-14'>
            <h1 className='section-title green'>Sow outside</h1>
            <div className='section-grid-container tile-grid'>
                {plants
                    ? plants.map((plant) => {
                        return (
                            <PlantTile
                                key={plant.subspecieId}
                                plant={plant}
                                id={plant.subspecieId}
                            />
                        );
                    })
                    : "No plants can be sowed outside at this location at this moment."}
            </div>
        </div>
    );
};

export default PlantsSowOutside;
