export const pickRandomAmount = (arr, amount) => {
  const result = [];

  let length;

  if (amount <= arr.length) {
    length = amount
  } else {
    length = arr.length
  }

  // Pick a random index, remove that element from the array, and add it to the result
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    result.push(arr.splice(randomIndex, 1)[0]);
  }

  return result;
};
