import Header from "../components/Header";
import relations from "../images/we_are_all_related.jpg";
import orange from "../images/orange.jpg";
import celery from "../images/celery.jpg";
import rotation from "../images/23_5deg.jpg";
import discontinuity from "../images/discontinuity.jpg";

const HomePage = () => {
  return (
    <div className='main-container grid_2-14'>
      <Header path='/ABOUT' title='ABOUT' loc={false} />
      <div  className='page-hero-container section-flex-container' >
          <h1 className='section-title green'>We are all connected</h1>
          <p className='essay-paragraph'>
            Our planet is home to a breathtaking variety of different species, each
            with its own unique character. All of them are interconnected in
            multiple ways.
          </p>
          <img src={orange} className='essay-image' />
          <img src={celery} className='essay-image' />
          <p className='essay-paragraph'>
            They exist because of their relationships, and some even exist in a
            state of symbiosis, where one species is the environment of another and
            vice versa. They affect each other through direct and indirect effects,
            and one species can have different effects on different species.
          </p>
          <p className='essay-paragraph'>
            Of all the species on our planet, those in the plant kingdom are of
            particular importance to us. They produce the oxygen we breathe and the
            carbohydrates and proteins we eat. We use them to build the structures
            we live in and to produce the garments we wear. Lastly, they have the
            magical power to transform our surroundings into a marvel of scents,
            colors, fruits, and essences.
          </p>
          <p className='essay-paragraph'>
            When different species meet, something special happens. For example,
            when orange and fennel come together in a delicous orange fennel salad.
          </p>
          <h1 className='section-subtitle'>A question of 23.5º</h1>
          <p className='essay-paragraph'>
            Our planet is a unique place due to its axis being inclined by 23.5º in
            relation to the sun. This simple geometrical fact has an enormous effect
            on our environment as it produces the sequence of alternating seasons.
          </p>
          <img src={rotation} className='essay-image' />
          <p className='essay-paragraph'>
            This discontinuity means that not everything is present all the time.
            This universal fact introduces the pleasant surprise of the melodic
            variation of essences that appear next to us during the course of the
            year.
          </p>
          <p className='essay-paragraph'>
            This application aims to reconnect us with the species that exist around
            us and remind us of the infinite potential hidden inside the
            relationships we can establish with them.
          </p>
          <p className='essay-paragraph'>
            It presents a particular selection of plants through their species and
            the properties of their cultivars. While the species help us recognize
            relationships between plants, the cultivars enable us to understand
            where and when a plant can grow, be harvested, or share its scent
            through its blossoms. Just switch between N and S and you will be
            presented with the plants that grow at that moment in your part of the
            planet.
          </p>
          <img src={discontinuity} className='essay-image' />
          <p className='essay-paragraph'>
            Like a botanical garden, this application invites you to walk through
            its infinite paths. If you encounter a species you like, just remember
            that you could share the warmth of your living room during the next
            winter and, in exchange, appreciate some fruits shortly after.
          </p>
      </div>
    </div>
  );
};

export default HomePage;
