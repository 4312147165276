import { Outlet } from "react-router-dom";
// import useAuth from "../hooks/useAuth";
import SideMenu from "../components/SideMenu";
import Footer from "../components/Footer";
import "../stylesheets/layout.css";
import "../stylesheets/grid.css";
import "../stylesheets/colours.css";

const Layout = () => {
  //   const { isAuthenticated, signOutUser, loginUser } = useAuth();

  return (
    <div>
      <SideMenu />
      <div id='content-wrapper' className='grid'>
        <Outlet />
        <div style={{ clear: "both" }}></div>
        <Footer path='/' title='NOW' loc={true} />
      </div>
    </div>
  );
};

export default Layout;
