const PlantHero = ({ plant }) => {
    return (
        <>
            {plant ? (
                <>
                    <div
                        style={{
                            backgroundImage: `url(https://plantea.aladlabs.net/uploads/images/${plant.imageId}/image1000_${plant.filename})`,
                        }}
                        className='section-hero-image desktop'
                    ></div>
                    <div
                        style={{
                            backgroundImage: `url(https://plantea.aladlabs.net/uploads/images/${plant.imageId}/image0700_${plant.filename})`,
                        }}
                        className='section-hero-image pad'
                    ></div>
                    <div
                        style={{
                            backgroundImage: `url(https://plantea.aladlabs.net/uploads/images/${plant.imageId}/image0500_${plant.filename})`,
                        }}
                        className='section-hero-image mobile'
                    ></div>
                </>
            ) : (
                "loading"
            )}
        </>
    );
};

export default PlantHero;
