import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocationContext } from "../contexts/LocationContext";
import Header from "../components/Header";
import Pagination from "../components/Pagination";

const RecipesPage = () => {
  const { location } = useLocationContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [recipes, setRecipes] = useState();
  const pageFragment = `?page=${currentPage}&pageSize=20`;
  const baseUrl = "https://plantea.aladlabs.net/api/v1/recipes/";
  const initialUrl = baseUrl + location + pageFragment;
  const [apiUrl, setApiUrl] = useState(initialUrl);

  const getData = async () => {
    try {
      const response = await fetch(apiUrl);
      const json = await response.json();
      setTotalPages(json.metadata.totalPages);
      setRecipes(json.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setApiUrl(initialUrl);
  }, [location, currentPage]);

  useEffect(() => {
    getData();
  }, [apiUrl]);

  return (
    <div className='main-container grid_2-14'>
      <Header path='/recipes' title='RECIPES' loc={true} />
      <div className={'content-container'}>
        { <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
        /> }
        <div className='link-list'>
          {recipes ?
            recipes.map((recipe) => {
              return (
                <div key={recipe.idMeal} className={"list list-item"}>
                  <NavLink to={`/recipes/${recipe.idMeal}`} key={recipe.idMeal}>
                    {recipe.strMeal}
                  </NavLink>
                  <br />
                </div>
              );
            })
              : "loading..."}
        </div>
        { <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
        /> }
      </div>
    </div>
  );
};

export default RecipesPage;
