import { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import Header from "../components/Header";
import EffectSpeciesGallery from "../components/EffectSpeciesGallery";

const EffectPage = () => {
  const { id } = useParams();
  const [effect, setEffect] = useState();
  const baseUrl = "https://plantea.aladlabs.net/api/v1/effects/";
  const initialUrl = baseUrl + id;
  const [apiUrl, setApiUrl] = useState(initialUrl);

  const getData = async () => {
    try {
      const response = await fetch(apiUrl);
      const json = await response.json();
      setEffect(json);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, [apiUrl]);

  return (
    <div className='main-container grid_2-14'>
      <Header path='/conditions' title='EFFECTS' loc={false} />
        <div className={'content-container'}>
          <div  className='page-hero-container section-flex-container' >
            <h1 className='section-title green'>{effect && effect.name}</h1>
          </div>
          <p>{effect && effect.description}</p>
          <div className='category-link'>
            <NavLink to={effect && `/conditions/${effect.conditionId}`}>
              {effect && effect.conditionName}
            </NavLink>
          </div>
          {effect && <EffectSpeciesGallery effect={effect} />}
        </div>
    </div>
  );
};

export default EffectPage;
