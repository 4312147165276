import {NavLink} from "react-router-dom";

const CultivarTaxonomy = ({ cultivar }) => {
    return (
        <div>
            <h1 className='section-title green'>{cultivar.cultivarName}</h1>
            {/*<table className='taxonomy'>*/}
            {/*    <tbody>*/}
            {/*        <tr>*/}
            {/*            <td>Seller:</td>*/}
            {/*            <td>{cultivar.producerName}</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td>Code:</td>*/}
            {/*            <td>{cultivar.sku}</td>*/}
            {/*        </tr>*/}
            {/*    </tbody>*/}
            {/*</table>*/}
            <NavLink
                to={`/plants/${cultivar.subspecieId}`}
                key={cultivar.subspecieId}
                className='subspecie-link'
            >
                {cultivar.subspecieName}
            </NavLink>
        </div>
    );
};

export default CultivarTaxonomy;
