import { useState, useEffect } from "react";
import { useLocationContext } from "../contexts/LocationContext";
import Header from "../components/Header";
import PlantTile from "../components/PlantTile";
import Pagination from "../components/Pagination";

const PlantsPage = () => {
  const { location } = useLocationContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [plants, setPlants] = useState();
  const pageFragment = `?page=${currentPage}&pageSize=20`;
  const baseUrl = "https://plantea.aladlabs.net/api/v1/species/harvesting/";
  const initialUrl = baseUrl + location + pageFragment;
  const [apiUrl, setApiUrl] = useState(initialUrl);

  const getData = async () => {
    try {
      const response = await fetch(apiUrl);
      const json = await response.json();
      setTotalPages(json.metadata.totalPages);
      setPlants(json.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setApiUrl(initialUrl);
  }, [location, currentPage]);

  useEffect(() => {
    getData();
  }, [apiUrl]);

  return (
    <div className='main-container grid_2-14'>
      <Header path='/plants' title='PLANTS' loc={true} />
      <div className={'content-container'}>
          { <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
          /> }
          <div className='tile-grid-container tile-grid'>
            {plants
                ? plants.map((plant) => {
                  return (
                      <PlantTile
                          plant={plant}
                          id={plant.subspecieId}
                          key={plant.scientificName}
                      />
                  );
                })
                : "loading..."}
          </div>
          { <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
          /> }
      </div>
    </div>
  );
};

export default PlantsPage;
