import { NavLink } from "react-router-dom";

const PlantEffects = ({ plant, effects }) => {
  return (
      <> {effects && plant.effects.length > 0 &&
          <>
              <div className='section-separator'></div>
              <h1 className='section-subtitle'>Effects</h1>
              <div className='link-list'>
                  {effects.map((effect) => {
                      return (
                          <div className={"list list-item"} key={effect.id}>
                              {">  "}
                              <NavLink
                                  to={`/effects/${effect.id}`}
                                  key={effect.id}
                                  className='list-item-link'
                              >
                                  {effect.name}
                              </NavLink>
                              <br />
                          </div>
                      );
                  })}
                  <NavLink
                      to={`/plants/${plant.subspecieId}/effects`}
                      className='list-more-link'
                  >
                      more effects of this species
                  </NavLink>
              </div>
          </>
        }
      </>
  );
};

export default PlantEffects;
