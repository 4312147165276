const PlantTaxonomy = ({ plant }) => {
  return (
    <div>
      <h1 className='section-title green'>{plant.scientificName}</h1>
      <table className='taxonomy'>
        <tbody>
          <tr>
            <td>Genus:</td>
            <td>{plant.genusName}</td>
          </tr>
          <tr>
            <td>Family:</td>
            <td>{plant.familyName}</td>
          </tr>
          <tr>
            <td>Order:</td>
            <td>{plant.orderName}</td>
          </tr>
          <tr>
            <td>Author:</td>
            <td>{plant.authorName}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PlantTaxonomy;
