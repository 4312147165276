import { NavLink } from "react-router-dom";

const EffectIdentity = ({ effect }) => {
    console.log(effect)
    return (
        <div className=''>
            {effect && <NavLink className={"section-title green"} to={"/effects/" + effect.id}>
                {effect.name}
            </NavLink>}
            <p>{effect && effect.description}</p>
            <div className='category-link'>
                {effect && <NavLink to={`/conditions/${effect.conditionId}`}>
                    {effect.conditionName}
                </NavLink>}
            </div>
            {/*{effect && <EffectSpeciesList effect={effect} />}*/}
        </div>
    );
};

export default EffectIdentity;
