import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocationContext } from "../contexts/LocationContext";
import PlantHero from "./PlantHero"

const DailyPlant = () => {
  const { location } = useLocationContext();
  const [plant, setPlant] = useState();
  const baseUrl = "https://plantea.aladlabs.net/api/v1/species/daily/";
  const initialUrl = baseUrl + location;
  const [apiUrl, setApiUrl] = useState(initialUrl);

  const getData = async () => {
    try {
      const response = await fetch(apiUrl);
      const plant = await response.json();
      setPlant(plant);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setApiUrl(initialUrl);
  }, [location, initialUrl]);

  useEffect(() => {
    getData();
  }, [apiUrl]);

  return (
    <div className='grid_2-14 section-flex-container'>
      <h1 className='section-label'>Plant of the day</h1>
      {plant && <NavLink className={"section-title green"} to={"/plants/" + plant.subspecieId}>
        {plant.scientificName}
      </NavLink>}
      {plant && <table className='taxonomy'>
        <tbody>
        <tr>
          <td>Genus:</td>
          <td>{plant.genusName}</td>
        </tr>
        <tr>
          <td>Family:</td>
          <td>{plant.familyName}</td>
        </tr>
        <tr>
          <td>Order:</td>
          <td>{plant.orderName}</td>
        </tr>
        <tr>
          <td>Author:</td>
          <td>{plant.authorName}</td>
        </tr>
        </tbody>
      </table>}
      <h1 className='specie-title'>{plant && plant.commonName}</h1>
      <PlantHero plant={plant} />
    </div>
  );
};

export default DailyPlant;
