import { NavLink } from "react-router-dom";

const PlantTile = ({ plant }) => {
  return (
    <div className='tile' key={plant.scientificName}>
      <NavLink to={plant && `/plants/${plant.subspecieId}`}>
          <>
              <div
                  style={{
                      backgroundImage: `url(https://plantea.aladlabs.net/uploads/images/${plant.imageId}/image1000_${plant.filename})`,
                  }}
                  className='tile-image desktop'
              ></div>
              <div
                  style={{
                      backgroundImage: `url(https://plantea.aladlabs.net/uploads/images/${plant.imageId}/image0700_${plant.filename})`,
                  }}
                  className='tile-image pad'
              ></div>
              <div
                  style={{
                      backgroundImage: `url(https://plantea.aladlabs.net/uploads/images/${plant.imageId}/image0500_${plant.filename})`,
                  }}
                  className='tile-image mobile'
              ></div>
          </>
        <div className='tile-title'>{plant && plant.commonName}</div>
      </NavLink>
    </div>
  );
};

export default PlantTile;
