const PlantEdibleUses = ({ plant, className }) => {
  return (
      <div className={className}>
        <h1 className='section-subtitle'>Edible Uses</h1>
        <p>{plant.edibleUses.replace(/\[[^\]]*\]/g, "")}</p>
      </div>
  );
};

export default PlantEdibleUses;
