import { NavLink } from "react-router-dom";

const RecipeSpecies = ({ ingredients }) => {
    const uniqueIngredients = [];

    for (const ingredient of ingredients) {
        if (ingredient.subspecieId !== "") {
            if (!uniqueIngredients.some(obj => obj.subspecieId === ingredient.subspecieId)) {
                uniqueIngredients.push(ingredient);
            }
        }
    }

  const uniqueIngredientList = uniqueIngredients.map(
    (ingredient, i) =>
      ingredient.subspecieId != "" && (
        <li key={ingredient.subspecieId}>
          <NavLink to={"/plants/" + ingredient.subspecieId}>
            {ingredient.scientificName} ({ingredient.name})
          </NavLink>{" "}
        </li>
      )
  );

  return (
    <div className='list link-list list-small'>
      With:
      <br />
      {uniqueIngredientList}
    </div>
  );
};

export default RecipeSpecies;
