import { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import Header from "../components/Header";
import RecipeIngredients from "../components/RecipeIngredients";
import RecipeSpecies from "../components/RecipeSpecies";

const RecipePage = () => {
  const { id } = useParams();
  const [recipe, setRecipe] = useState();
  const baseUrl = "https://plantea.aladlabs.net/api/v1/recipes/";
  const initialUrl = baseUrl + id;
  const [apiUrl, setApiUrl] = useState(initialUrl);

  const getData = async () => {
    try {
      const response = await fetch(apiUrl);
      const json = await response.json();
      setRecipe(createRecipeIngredients(json));
    } catch (error) {
      console.log(error.message);
    }
  };

  const createRecipeIngredients = (recipe) => {
    recipe["ingredients"] = [];

    for (let i = 1; i <= 20; i++) {
      const ingredient = recipe[`strIngredient${i}`];
      const subspecieId = recipe[`subspecieId${i}`] || "";
      const measure = recipe[`strMeasure${i}`];
      const scientificName = recipe[`subspecieScientificName${i}`];

      if (ingredient != "" && ingredient != null) {
        recipe["ingredients"].push({
          id: id,
          name: ingredient,
          scientificName: scientificName,
          subspecieId: subspecieId,
          measure: measure,
        });
      }
      delete recipe[`strIngredient${i}`];
      delete recipe[`subspecieId${i}`];
      delete recipe[`strMeasure${i}`];
      delete recipe[`subspecieScientificName${i}`];
    }

    return recipe;
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='main-container grid_2-14'>
        <Header path='/recipes' title='RECIPES' loc={false} />
        <div className={'content-container'}>
            <div  className='page-hero-container section-flex-container' >
                <h1 className='section-title green'>{recipe && recipe.strMeal}</h1>
            </div>
            <div>{recipe && <RecipeSpecies ingredients={recipe.ingredients} />}</div>
            <h1 className='section-subtitle'>Instructions</h1>
            <div>
            {recipe
              ? recipe.strInstructions.split("\n").map((instruction, index) => (
                  <div key={index} className='instructions-paragraph'>
                    {instruction}
                  </div>
                ))
              : "loading"}
            </div>
            <h1 className='section-subtitle'>Ingredients</h1>
            {recipe && <RecipeIngredients ingredients={recipe.ingredients} />}
            <h1 className='section-subtitle'>Effects</h1>
            <div>
            {recipe
              ? recipe.effects.map((effect) => (
                  <div className={"list list-item"} key={effect.id}>
                    {">  "}
                    <NavLink
                      to={`/effects/${effect.id}`}
                      key={effect.id}
                      className='list-item-link'
                    >
                      {effect.name}
                    </NavLink>
                  </div>
                ))
              : "loading"}
            </div>
        </div>
    </div>
  );
};

export default RecipePage;
