import { NavLink } from "react-router-dom";

const RecipeIngredients = ({ ingredients }) => {
  const myIngrList = ingredients.map((ingredient, i) =>
    ingredient.name != null && ingredient.subspecieId != "" ? (
      <li key={i}>
        <NavLink to={"/plants/" + ingredient.subspecieId}>
          {ingredient.name}
        </NavLink>{" "}
        {ingredient.measure}
      </li>
    ) : (
      <li key={i}>
        {ingredient.name} {ingredient.measure}
      </li>
    )
  );

  return <div className='list dashed-list list-medium'>{myIngrList}</div>;
};

export default RecipeIngredients;
