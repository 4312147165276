import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import ConditionEffects from "../components/ConditionEffects";

const ConditionPage = () => {
  const { id } = useParams();
  const [condition, setCondition] = useState();
  const baseUrl = "https://plantea.aladlabs.net/api/v1/conditions/";
  const initialUrl = baseUrl + id;
  const [apiUrl, setApiUrl] = useState(initialUrl);

  const getData = async () => {
    try {
      const response = await fetch(apiUrl);
      const json = await response.json();
      setCondition(json);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='main-container grid_2-14'>
      <Header path='/conditions' title='EFFECTS' loc={false} />
      <div  className='page-hero-container section-flex-container' >
        <h1 className='section-title green'>{condition && condition.name}</h1>
      </div>
      <p>{condition && condition.description}</p>
      {condition && <ConditionEffects conditionId={condition.conditionId} />}
    </div>
  );
};

export default ConditionPage;
