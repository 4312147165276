import ginkgo from "../images/ginkgo.png";
import "../stylesheets/footer.css";
import {NavLink} from "react-router-dom";
import {useLocationContext} from "../contexts/LocationContext";

const Footer = ({ path, title, loc }) => {
    const { location, setLocation } = useLocationContext();
    const capitalizedLocation =
        location.charAt(0).toUpperCase() + location.slice(1);

    const handleChange = (event) => {
        // setLocation(event.target.value);
        if (event.target.value === 'n') {
            localStorage.setItem("location", "n");
            setLocation("n");
        } else if (event.target.value === 's') {
            localStorage.setItem("location", "s");
            setLocation("s");
        }
    };

  return (
    <div className='footer grid_1-15'>
        <div className={"grid"}>
            <div className={"grid_2-14 footer-stamp"}>
                {location === "n" && (<><span className={'active-hemisphere'}>N</span> | <span className={'hemisphere'}>S</span>
                </>)}

                {location === "s" && (<><span className={'hemisphere'}>N</span> | <span className={'active-hemisphere'}>S</span>
                </>)}
            </div>
            <div className={"grid_2-14 footer-navigation-container"}>
                <div className="footer-title">
                    <NavLink to={path} className='main-link'>
                        {title}
                    </NavLink>
                    {loc === true && <div className='location mobile'>{capitalizedLocation}</div>}
                    {loc === true && <select id="hemisphere-selector" className={"location desktop"} value={location} onChange={handleChange}>
                        <option value="n">N</option>
                        <option value="s">S</option>
                    </select>}
                </div>
                <div className='desktop'>
                    <nav className='footer-menu'>
                        <NavLink className='1' to='/'>
                            Now
                        </NavLink>{" | "}
                        <NavLink to='/plants'>
                            Plants
                        </NavLink>{" | "}
                        <NavLink to='/recipes'>
                            Recipes
                        </NavLink>{" | "}
                        <NavLink to='/conditions'>
                            Effects
                        </NavLink>{" | "}
                        <NavLink to='/about'>
                            About
                        </NavLink>
                    </nav>
                </div>
            </div>
            <div className={"grid_5-8 footer-hero"}>
                <img src={ginkgo} alt={"plantea"} className='image' style={{height: '8rem', width: '8rem'}}/>
            </div>
            <div className={"grid_2-14 footer-credits-container"}>
                <div>
                    <div className={"footer-project-title"}>Plantea is part of the Brandenburg Trilogy</div>
                    <div className={"footer-project-authors"}>a project by <a href={'https://www.aladlabs.net'} target="_blank" rel="noopener noreferrer">ALADlabs</a> with <a href={'https://www.stephanjung.eu'} target="_blank" rel="noopener noreferrer">Stephan Jung</a>.</div>
                    <div className={"footer-project-credits"}><span style={{ fontWeight: 'bold' }}>concept:</span> <a href={'https://www.stephanjung.eu'} target="_blank" rel="noopener noreferrer">Stephan Jung</a>, <span style={{ fontWeight: 'bold' }}>design, code & data:</span> <a href={'https://www.aladlabs.net'} target="_blank" rel="noopener noreferrer">ALADlabs</a>, <span style={{ fontWeight: 'bold' }}>data-team:</span> Sabrina Colombo, Oleksandra Shkolova and Stephan Jung <span style={{ fontWeight: 'bold' }}>code-team:</span> Oleksandra Shkolova, Anja Hofmann and Stephan Jung <span style={{ fontWeight: 'bold' }}>with precious contributions by</span> Julian Marconi, Matthias 'AlpineWeezl' Wieser, the real Owen Reese & the outstanding Sergio Rodriguez Estevez <span style={{ fontWeight: 'bold' }}>as well as</span> <a href={'https://www.biolib.de'} target="_blank" rel="noopener noreferrer">www.biolib.de</a>.</div>
                </div>
                <div className={"footer-copyright"}>
                    © { new Date().getFullYear() } Plantea, ALADlabs
                </div>
            </div>
        </div>
    </div>
  );
};

export default Footer;
