import { NavLink } from "react-router-dom";

const PlantCultivars = ({ plant, cultivars }) => {
    return (
        <>
            <h1 className='section-subtitle'>Cultivars</h1>
            <div className='link-list'>
                {cultivars &&
                    cultivars.map((cultivar) => {
                        return (
                            <div className={"list list-item"} key={cultivar.cultivarId}>
                                {">  "}
                                <NavLink
                                    to={`/plants/${plant.subspecieId}/cultivars/${cultivar.cultivarId}`}
                                    key={plant.subspecieId}
                                    className='list-item-link'
                                >
                                    {cultivar.cultivarName}
                                </NavLink>
                                <br />
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default PlantCultivars;
