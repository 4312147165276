import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Header from "../components/Header";
import bodies from "../images/bodies.png";

const ConditionsPage = () => {
  const [conditions, setConditions] = useState();
  const baseUrl = "https://plantea.aladlabs.net/api/v1/conditions";
  const initialUrl = baseUrl;
  const [apiUrl, setApiUrl] = useState(initialUrl);

  const getData = async () => {
    try {
      const response = await fetch(apiUrl);
      const json = await response.json();
      setConditions(json);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='main-container grid_2-14'>
      <Header path='/conditions' title='EFFECTS' loc={false} />
      <div  className='page-hero-container section-flex-container' >
      <img src={bodies} className='conditions-image' />
      </div>
      <div className='link-list'>
        {conditions &&
          conditions.map((condition) => {
            return (
              <div key={condition.conditionId} className={"list list-item"}>
                <NavLink
                  key={condition.conditionId}
                  to={`/conditions/${condition.conditionId}`}
                >
                  {condition.name}
                </NavLink>
                <br />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ConditionsPage;
