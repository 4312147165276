import { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import Header from "../components/Header";

const PlantRecipesPage = () => {
  const { id } = useParams();

  const [plant, setPlant] = useState();
  const [recipes, setRecipes] = useState();
  const plantUrl = "https://plantea.aladlabs.net/api/v1/species/" + id;
  const baseUrl = "https://plantea.aladlabs.net/api/v1/species/";
  const initialUrl = baseUrl + id + "/recipes";
  const [apiUrl, setApiUrl] = useState(initialUrl);

  const getPlantData = async () => {
    try {
      const response = await fetch(plantUrl);
      const json = await response.json();
      setPlant(json);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getRecipeData = async () => {
    try {
      const response = await fetch(apiUrl);
      const json = await response.json();

      setRecipes(json);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getPlantData();
    getRecipeData();
  }, []);

  return (
    <div className='main-container grid_2-14'>
      <Header path='/plants' title='PLANTS' loc={true} />
      <div className={'content-container'}>
        <h1 className='section-title'>
          <NavLink className={"green"} to={"/plants/" + id}>
            {plant && plant.scientificName}
          </NavLink>
        </h1>
        <table className='taxonomy' style={{ margin: "2rem 0" }}>
          <tbody>
            <tr>
              <td>Genus:</td>
              <td>{plant && plant.genusName}</td>
            </tr>
            <tr>
              <td>Family:</td>
              <td>{plant && plant.familyName}</td>
            </tr>
            <tr>
              <td>Order:</td>
              <td>{plant && plant.orderName}</td>
            </tr>
            <tr>
              <td>Author:</td>
              <td>{plant && plant.authorName}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* {plant && <SpecieHero plant={plant} />} */}
      {/* {plant && <PlantIdentity plant={plant} />} */}
      <h1 className='section-title'>Recipes</h1>
      <div className='link-list'>
        {recipes &&
          recipes.map((recipe) => {
            return (
              <div className={"list list-item"} key={recipe.idMeal}>
                {">  "}
                <NavLink
                  to={`/recipes/${recipe.idMeal}`}
                  key={recipe.idMeal}
                  className='list-item-link'
                >
                  {recipe.strMeal}
                </NavLink>
                <br />
              </div>
            );
          })}
      </div>
      {/* {plant && <PlantMedicalUses plant={plant} />} */}
    </div>
  );
};

export default PlantRecipesPage;
