const AccordionSection = ({ children, isOpen, label, onClick }) => {
    const handleClick = () => {
        onClick(label);
    };

    return (
        <div className={isOpen ? 'accordion-section-open' : 'accordion-section-closed'}>
            <div onClick={handleClick} className={"accordion-label"}>
                <h1 className='accordion-title'>{label}</h1>
                <div className={"accordion-symbol"}>
                    {!isOpen && <span>+</span>}
                    {isOpen && <span>-</span>}
                </div>
            </div>
            {isOpen && (
                <div className={"accordion-content"} >
                    <p>{children}</p>
                </div>
            )}
        </div>
    );
};

export default AccordionSection;